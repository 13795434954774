import React from 'react';
import { ApiHook } from '../../../hooks/apiHook';
import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import CurrencyConverter from '../../../Currency/CurrencyConverter';
import { useTranslation } from 'react-i18next';
import SubmitButton from '../../Common/buttons/SubmitButton';


const CartItems = (props) => {
    const queryClient = useQueryClient();
    const { t } = useTranslation()
    //------------------------------- API ----------------------------------
    const deleteCartItem = ApiHook.CallDeleteGuestOrder();
    const processOrder = ApiHook.CallProcessOrder();
    const createOrder = ApiHook.CallCreateGuestOrder();

    const handleIncrement = (item) => {
        const data = {
            partyId: item.partyId,
            guestId: item.guestId,
            productId: item.productId,
            quantity: 1
        };
        createOrder.mutate(data, {
            onSuccess: (res) => {
                if (res.status) {
                    queryClient.invalidateQueries({ queryKey: ["party-products"] });
                } else {
                    toast.error(res.description);
                }

            }
        })
    };

    const handleDecrement = (item) => {
        if (item.quantity === 1) {
            return handleDelete(item.id);
        } else {
            const data = {
                partyId: item.partyId,
                guestId: item.guestId,
                productId: item.productId,
                quantity: -1
            };
            createOrder.mutate(data, {
                onSuccess: (res) => {
                    if (res.status) {
                        queryClient.invalidateQueries({ queryKey: ["party-products"] });
                    } else {
                        toast.error(res.description);
                    }

                }
            })
        }

    }

    const handleDelete = (id) => {
        const data = {
            partyId: props.partyId,
            guestId: props.guestId,
            cartId: id
        }
        if (data.cartId) {
            deleteCartItem.mutate(data, {
                onSuccess: (res) => {
                    if (res.status) {
                        toast.success(res.data)
                        queryClient.invalidateQueries({ queryKey: ['party-products'] })
                    }
                }
            })
        }

    };
    const handleContinue = () => {
        const data = {
            guestIdArr: [props.guestId],
            cartIdArr: props.orders.map(order => order.id)
        }
        if (props.orders?.length > 0) {
            processOrder.mutate(data, {
                onSuccess: (res) => {
                    if (res.status) {
                        toast.success(res.data)
                        queryClient.invalidateQueries({ queryKey: ['party-products'] })
                    } else {
                        toast.error(res.data.description)
                    }
                }
            })
        }
    }

    return (

        <div className="table-responsive min-hieght-table-2 mb-4">
            <div className="p-3">
                <div className="py-0">
                    <h5 className="m-0">{t('itemsInTheCart')}</h5>
                </div>

                <table className="table table-manage-invite-party-portal mb-0 border-top1">
                    <thead>
                        <tr>
                            <th>{t('#')}</th>
                            <th>{t('product_name')}</th>
                            <th>{t('price')}</th>
                            <th>{t('quantity')}</th>
                            <th>{t('totalPrice')}</th>
                            <th>{t('action')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {props?.orders?.length > 0 ?
                            (
                                props?.orders?.map((item, index) => (
                                    <tr key={item?.id}>
                                        <td>{index + 1}</td>
                                        <td>{item?.productName}</td>
                                        <td>{props.currency?.symbolLeft}{" "}{CurrencyConverter(item?.price, props.conversionFactor)}</td>
                                        <td>
                                            <div className="quantity">
                                                <button className="minus" aria-label="Decrease" disabled={createOrder.isLoading} onClick={() => handleDecrement(item)}>&minus;</button>
                                                <input
                                                    readOnly
                                                    type="text"
                                                    className="input-box"
                                                    value={item?.quantity}
                                                    min="1"
                                                    max="10"
                                                />
                                                <button className="plus" aria-label="Increase" disabled={createOrder.isLoading} onClick={() => handleIncrement(item)}>+</button>
                                            </div>
                                        </td>
                                        <td>{props.currency?.symbolLeft}{" "}{CurrencyConverter(item?.totalAmount, props.conversionFactor)}</td>
                                        <td>
                                            <button onClick={() => handleDelete(item.id)} type='button' className="btn add_btn_delete_modal btn-outline-danger"><i className="fa-solid fa-trash"></i>
                                            </button>
                                        </td>
                                        <td></td>
                                    </tr>
                                ))
                            ) : (<tr><td colSpan="5">{t('noDataFound')}</td></tr>)}
                    </tbody>
                </table>
                <SubmitButton
                    click={handleContinue}
                    className="btn mt-1 py-2 rounded-1 setup-Party text-white btn-back-option mt-3 me-3"
                    text={"continue"}
                    isLoading={processOrder.isLoading}
                    isSubmitting={processOrder.isLoading}
                />
            </div>
        </div>
    );
};

export default CartItems;
